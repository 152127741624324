<template>
    <rsck-page background="#fff" v-if="showResult" class="page-container">
        <template v-for="(item,index) in pageSetting.drawer">
            <services-textarea
                    v-if="item.type == 'textarea'"
                    :title="item.label"
                    :default-value="item.value"
                    :ref="item.field_name"
                    :placeholder="item.placeholder"
                    :max-number="item.max"
                    :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
            />
            <service-select
                    v-if="item.type == 'select'"
                    :title="item.label"
                    :ref="item.field_name"
                    :options="item.options"
                    :name="item.field_name"
                    :default-value="item.value"
                    :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                    :placeholder="item.placeholder"
                    @onSelect="onSelect"
            />
            <services-village-select
                    v-if="item.type == 'village_select'"
                    :title="item.label"
                    :ref="item.field_name"
                    :options="item.options"
                    :placeholder="item.placeholder"
                    :default-value="item.value"
                    :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
            />
            <services-input
                    v-if="item.type == 'text'"
                    :title="item.label"
                    :unit="item.unit"
                    :ref="item.field_name"
                    :placeholder="item.placeholder"
                    :default-value="item.value"
                    :disabled="item.disabled"
                    :name="item.field_name"
                    :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                    @onInputBlur="onInputBlur"
            />
            <services-upload
                    v-if="item.type == 'upload'"
                    :title="item.label"
                    :placeholder="item.placeholder"
                    :max="item.max"
                    :ref="item.field_name"
                    :style="{marginTop:item.marginTop+'px',marginBottom:item.marignBottom+'px'}"
                    :can-remove="true"
            />
            <div class="border-box" v-if="item.bottomBorder"></div>
        </template>

        <div class="submit-button">
            <div class="button" @click="getParams">{{pageSetting.button_text || '确定'}}</div>
        </div>

        <div class="space-line"></div>

        <rsck-loading ref="loading" text="数据保存中"></rsck-loading>
    </rsck-page>
</template>

<script>
  import RsckPage from '@/components/common/rsck-page'
  import RsckRefresh from '@/components/common/rsck-refresh'
  import ServicesTextarea from '@/pages/services/components/services-textarea'
  import ServiceSelect from '@/pages/services/components/services-select'
  import ServicesVillageSelect from '@/pages/services/components/services-village-select'
  import ServicesInput from '@/pages/services/components/services-input'
  import ServicesUpload from '@/pages/services/components/services-upload'
  import RsckLoading from '@/components/common/rsck-loading'

  export default {
    name: 'add-service',
    components: {
      RsckLoading,
      ServicesUpload,
      ServicesInput, ServicesVillageSelect, ServiceSelect, ServicesTextarea, RsckRefresh, RsckPage
    },
    mounted () {
      let query = this.$route.query
      this.listTypeId = query?.listTypeId || 1
      if (this.listTypeId == 1) {
        document.title = '二手商品'
      } else if (this.listTypeId == 2) {
        document.title = '社区服务'
      }
      this.getPageSetting()
    },
    data () {
      return {
        pageSetting: [],
        showResult: false,
        listTypeId: 1,
        canContinue: true,
        hasError: false
      }
    },
    methods: {
      getPageSetting () {
        this.$api.services.getAddPageSetting(
          {list_type_id: this.listTypeId}
        ).then(response => {
          this.pageSetting = response.data
          this.pageSetting.drawer.forEach(drawer => {
            drawer.disabled = drawer?.disabled || false
            console.log(drawer)
            if (drawer.field_name == 'village_id') {
              if (!drawer.value) {
                drawer.value = parseInt(drawer.value)
              }
            }
          })
          document.title = this.pageSetting.title || '添加商品'
          this.showResult = true
        })
      },
      getParams () {
        if (this.canContinue) {
          this.canContinue = false

          let setting = []
          this.pageSetting.drawer.forEach(item => {
            setting.push({
              required: item.required,
              name: item.field_name,
              message: item.placeholder ? item.placeholder : (item.label) + '不能为空'
            })
          })

          let params = {}
          try {
            setting.forEach(item => {
              let value = this.$refs[item.name][0].getValue()
              if (item.required == true && value == '') {
                this.$toast.warn(item.message)
                throw new Error(item.message)
              }
              params[item.name] = value
            })
          } catch (e) {
            this.canContinue = true
            return
          }

          params.list_type_id = this.listTypeId

          this.$refs.loading.show()
          this.$api.services.saveAddData(params).then(response => {
            this.$refs.loading.hide()
            this.$toast.success('保存成功')

            setTimeout(() => {
              let query = {
                ...response.data,
                listTypeId: this.listTypeId
              }
              query = encodeURIComponent(JSON.stringify(query))
              this.$platform.wxsdk.wxRoute({
                type: 'redirectTo',
                url: '/web/services/goods-info?query=' + query,
              })
            }, 1000)

          }).catch(error => {
            this.$toast.error(error.message)
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)

        }

      },
      onInputBlur (e) {
        let data = e
        let name = data.name
        let value = data.value

        if (name == 'price') {
          //请求接口，计算结算价格
          let classId = this.$refs.classify_id[0].getValue()
          this.$api.services.getRebatePrice({price: value, classify_id: classId}).then(response => {
            let rebate_price = response.data
            if (typeof this.$refs.rebate_price != 'undefined') {
              this.$refs.rebate_price[0].setValue(rebate_price)
            }
            this.$forceUpdate()
          })
        }
      },
      onSelect (e) {
        let data = e
        let name = data.name
        let value = data.value

        if (name == 'classify_id') {
          //请求接口，计算结算价格
          let price = this.$refs.price[0].getValue()
          this.$api.services.getRebatePrice({price: price, classify_id: value}).then(response => {
            let rebate_price = response.data
            if (typeof this.$refs.rebate_price != 'undefined') {
              this.$refs.rebate_price[0].setValue(rebate_price)
            }
            this.$forceUpdate()
          })
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .page-container {
        padding: 0 15px;
    }

    .border-box {
        border-bottom: #EBEBEB solid 1px;
        padding-top: 20px;
    }

    .submit-button {
        width: 100%;
        margin-top: 20px;

        .button {
            background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
            height: 44px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
        }
    }

    .space-line {
        width: 100%;
        height: 20px;
    }
</style>